import React, { useState, useEffect, useContext, useRef } from "react";
import { Buffer } from "buffer";
import { CurrentUserContext } from '../App.js';
import { useNavigate, useLocation } from "react-router-dom";
import ReactCaroussel from "react-caroussel";
import "react-caroussel/dist/index.css";
import Loginpage from "./Loginpage.js";
import Loader from './Loader.js';
import { debounce } from 'lodash';
import CDP_upddate from './CDP_upddate.js'
import Menu from './Images/menu.png.png';
import Close from './Images/close.png.png';
import ArrowL from './Images/ArrowL.png';
import ArrowR from './Images/ArrowR.png';
import './DynamicWebsite.css';
import FilePreview from './FilePreview.js';

const App = () => {
    console.log("Console tocheck is it deployed")
    const { Complete_website_data, setCompleteWebsiteData, websiteData, setWebsiteData
        , headerData, setData, wLoader, setWLoader, base_url, setBaseUrl, cdpDetails, setCdpDetails,
        citiyMaster, setCityMaster, openCDP, setOpenCDP, openLogin, setOpenLogin, setSelectedBtn
    } = useContext(CurrentUserContext);
    const [selectedReadMore, setSelectedReadMore] = useState('null');
    const [isDashboardPage, setDashBoardPage] = useState(true);
    const encrypt = (id) => {
        return Buffer.from(id.toString()).toString("base64");
    };
    const decrypt = (encryptedId) => {
        return Buffer.from(encryptedId, "base64").toString("ascii");
    };
    // console.log("CDP details", cdpDetails);
    const navigate = useNavigate();
    const location = useLocation();
    const currentId = location.pathname.substring(1).split('/')[1];
    const currentPageName = location.pathname.substring(1).split('/')[0];
    useEffect(() => {
        const pathSegments = location.pathname.substring(1).split('/');
        if (pathSegments.length === 4) {
            const lastSegment = pathSegments[pathSegments.length - 1];
            setSelectedReadMore(decrypt(lastSegment))
        }
    }, [])
    const handleReadMoreClose = () => {
        setSelectedReadMore('null');
        const pathSegments = location.pathname.substring(1).split('/');
        if (pathSegments.length === 4) {
            const newPath = '/' + pathSegments.slice(0, -2).join('/');
            navigate(newPath);
        }
    }
    const handlePathClick = (id, name = 's') => {
        const s = name.toLowerCase().split(' ').join('');
        const a = encrypt(id);
        navigate(`/${s}/${a}`);
    };
    const readMoreClick = (name = 's', id) => {
        const s = name.toLowerCase().split(' ').join('');
        const a = encrypt(id);
        const currentPath = location.pathname;
        navigate(`${currentPath}/${s}/${a}`);
    }

    const [selectNavButtom, setSelectedNavigationButton] = useState('saty');
    const sectionRef = useRef(null);
    const [modalContent, setModalContent] = useState({
        isOpen: false,
        title: '',
        description: '',
        image: ''
    });

    const scrollUp = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            const currentPosition = window.scrollY;
            window.scrollTo({ top: -12, behavior: 'smooth' });
        }
    };
    useEffect(() => {
        async function foo() {
            const myHeaders = new Headers();
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };
            fetch(`${base_url}/api/v1/master_data/hospital_masters/cities`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setCityMaster([...result]);
                }
                )
                .catch((error) => console.error(error));
        }
        foo();
    }, [])
    const fetchpages = async (id, name = 's', type = 'website_id') => {
        if (type == 'page_id') {
            setDashBoardPage(false);
        } else {
            setDashBoardPage(true);
        }
        setSelectedReadMore('null');
        handlePathClick(id, name);
        setCurrentIndex(0);
        setWLoader(true);
        try {
            const myHeaders = new Headers();
            myHeaders.append("Token", "Cez3WtByoFEfgwY4AunA");
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };
            fetch(`${base_url}/api/v1/master_data/website_section_template?${type}=${id}`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log('headerData', headerData, "result", result);
                    const main = headerData?.website_sections || [];
                    const page = result?.website_sections || [];

                    const filteredMainSections = main.filter(section => section.w_section_type_master_id === 1 || section.w_section_type_master_id === 7);

                    filteredMainSections.forEach(mainSection => {
                        const existsInPage = page.some(pageSection => pageSection.w_section_type_master_id === mainSection.w_section_type_master_id);
                        if (!existsInPage) {
                            if (mainSection.w_section_type_master_id === 1) {
                                page.unshift(mainSection);
                            } else if (mainSection.w_section_type_master_id === 7) {
                                page.push(mainSection);
                            }
                        }
                    });
                    scrollUp();
                    setWebsiteData([...result?.website_sections]);
                    setWLoader(false);
                })
                .catch((error) => console.error(error));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const onLanding = async (id, headerData) => {
        const original_id = decrypt(id);
        setSelectedNavigationButton(original_id);
        if (headerData.id == original_id) {
            setDashBoardPage(true);
        }
        setCurrentIndex(0);
        setWLoader(true);

        try {
            const myHeaders = new Headers();
            myHeaders.append("Token", "Cez3WtByoFEfgwY4AunA");
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };
            fetch(`${base_url}/api/v1/master_data/website_section_template?${(headerData.id == original_id
                && currentPageName == headerData?.name?.replace(/\s+/g, '').toLowerCase()
            ) ? 'website_id' : 'page_id'}=${original_id}`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log('headerData', headerData, "result", result);
                    const main = headerData?.website_sections || [];
                    const page = result?.website_sections || [];

                    const filteredMainSections = main.filter(section => section.w_section_type_master_id === 1 || section.w_section_type_master_id === 7);

                    filteredMainSections.forEach(mainSection => {
                        const existsInPage = page.some(pageSection => pageSection.w_section_type_master_id === mainSection.w_section_type_master_id);
                        if (!existsInPage) {
                            if (mainSection.w_section_type_master_id === 1) {
                                page.unshift(mainSection);
                            } else if (mainSection.w_section_type_master_id === 7) {
                                page.push(mainSection);
                            }
                        }
                    });
                    scrollUp();
                    setWebsiteData([...result?.website_sections]);
                    setWLoader(false);
                })
                .catch((error) => {
                    setWLoader(false);
                    console.error(error)
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setWLoader(true);
            try {
                if (Complete_website_data !== "") {
                    const myHeaders = new Headers();
                    myHeaders.append("Token", "Cez3WtByoFEfgwY4AunA");
                    const requestOptions = {
                        method: "GET",
                        headers: myHeaders,
                        redirect: "follow"
                    };
                    fetch(`${base_url}/api/v1/master_data/website_section_template?domain_name=${Complete_website_data}`, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            setData(result);
                            setWebsiteData([...result?.website_sections]);
                            setWLoader(false);

                            const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
                            link.type = 'image/x-icon';
                            link.rel = 'shortcut icon';
                            document.title = result.name;
                            link.href = result?.logo_img_url;
                            document.getElementsByTagName('head')[0].appendChild(link);
                            // Set the selected navigation button and fetch pages if currentId is available
                            // Update OG image
                            const ogImage = document.querySelector("meta[property='og:image']") || document.createElement('meta');
                            ogImage.setAttribute("property", "og:image");
                            ogImage.setAttribute("content", result.logo_img_url);
                            document.head.appendChild(ogImage);

                            // Update OG title
                            const ogTitle = document.querySelector("meta[property='og:title']") || document.createElement('meta');
                            ogTitle.setAttribute("property", "og:title");
                            ogTitle.setAttribute("content", result.name);
                            document.head.appendChild(ogTitle);




                            if (currentId) {
                                onLanding(currentId, result);
                            }
                            return () => {
                                link.remove();
                                ogImage.remove(); //Remove
                                ogTitle.remove(); //Remove
                            };
                        })
                        .catch((error) => {
                            setWLoader(false);
                            console.error(error);
                        });
                }
            } catch (error) {
                setWLoader(false);
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [Complete_website_data]);
    // here we will write one more useEffect with dynamic_sectins that will show based on the select_nav_link  when ever it updates
    //whole section will updated based the select_nav_link except navigation and footer we can filter section_type_id 1 or 7
    const sortArrayByOrderId = (arr) => {
        return arr.sort((a, b) => a.order_id - b.order_id);
    };

    const [currentIndex, setCurrentIndex] = useState(0);
    const handleScrolltoSection = (e, href) => {
        e.preventDefault();
        const target = document.querySelector(href);
        const offset = 100;
        const targetPosition = target.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = targetPosition - offset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    };

    const [sideHover, setAutoplay] = useState(null);
    const handleMouseEnter = (a) => setAutoplay(a);
    const handleMouseLeave = (a) => {
        setAutoplay(a);
    }

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const [selectedOption, setSelectedOption] = useState("Other Solutions");
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (section) => {
        setSelectedOption(section.section_title);
        setIsOpen(false);
        handleScrolltoSection(null, `#s-${section.action_path}`);
    };

    const [contactUs, setContactUs] = useState({ name: '', mobile: '', message: '' });
    const handleChangeContactUS = (e) => {
        const { name, value } = e.target;
        setContactUs((prevContactUs) => ({ ...prevContactUs, [name]: value }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, number, message } = contactUs;
        const mailtoLink = `mailto:recipient-nanohealth@gmail.com?subject=Contact Form Submission&body=${encodeURIComponent(
            `Name: ${name}\nNumber: ${number}\nMessage: ${message}`
        )}`;
        console.log(mailtoLink);
        // window.location.href = mailtoLink;
    };
    const [viewMoreFlag, setViewMoreFlag] = useState({
        flag: false,
        section_title: '',
        content_img: ''
    });
    // const [openCDP, setOpenCDP] = useState(true);
    const [SelectedDropDownBtn, setSelectedDropDownBtn] = useState('nanoHealth');

    const [slidesToShow, setSlidesToShow] = useState(2);
    useEffect(() => {
        function updateSlidesToShow() {
            if (window.innerWidth <= 768) {
                setSlidesToShow(1);
            } else {
                setSlidesToShow(2);
            }
        };
        const handleResize = debounce(updateSlidesToShow, 100);
        updateSlidesToShow();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const [impagePreview, setImpagePreview] = useState(false);
    const [urlData, setUrlData] = useState(null);

    const getContentBodyValue = (content_body) => {
        if (typeof content_body === 'object') {
            return {
                value: content_body.value,
                x: content_body.x || 0,
                y: content_body.y || 0
            };
        }

        if (typeof content_body === 'string') {
            if (content_body.includes('{')) {
                try {
                    const cleanJson = content_body
                        .replace(/=>/g, ':')
                        .replace(/[\n\r]/g, '')
                        .replace(/([{,]\s*)([a-zA-Z0-9_]+)(\s*:)/g, '$1"$2"$3');
                    const parsed = JSON.parse(cleanJson);
                    return {
                        value: parsed.value,
                        x: parsed.x || 0,
                        y: parsed.y || 0
                    };
                } catch (e) {
                    return {
                        value: content_body,
                        x: 0,
                        y: 0
                    };
                }
            }
            return {
                value: content_body,
                x: 0,
                y: 0
            };
        }

        return {
            value: '',
            x: 0,
            y: 0
        };
    };

    return (
        <>
            {impagePreview && <FilePreview isOpen={impagePreview} onClose={setImpagePreview} url={urlData} />}
            {openCDP && <CDP_upddate cdpDetails={cdpDetails} openCDP={openCDP} setOpenCDP={setOpenCDP} />}
            {openLogin && <Loginpage openLogin={openLogin} setOpenLogin={setOpenLogin} />}
            <div>
                {wLoader &&
                    <div
                        style={{
                            position: 'fixed', top: 0, left: 0,
                            background: 'rgba(0,0,0,0.2)',
                            zIndex: 10000, height: '100vh', width: '100vw',
                            display: 'flex', justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <Loader />
                    </div>}
                <div
                    style={{
                        border: "0.5px solid gray", borderRadius: '5px',
                        height: 'auto', width: '100%', marginTop: '0vh',
                        display: 'flex',
                        //  maxWidth: '41vh',
                        flexDirection: 'column', // Align children vertically
                        justifyContent: 'space-around', // Space between children
                        flexWrap: 'wrap', // Wrap children to next line if needed
                        overflow: 'hidden', // Prevent overflow
                        boxShadow: '0px 0px 5px 0px #747474'
                    }}
                >
                    {viewMoreFlag.flag && <div className='viewmoreM' style={{ alignItems: 'center' }} onClick={(e) => {
                        e.stopPropagation();
                        setViewMoreFlag({
                            flag: false, // or false depending on your logic
                            section_title: '',
                            content_img: ''
                        });

                    }}><div className='viewmoreContainer' onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} >
                            <span aria-label='convert' title='Close'>
                                <button className='viewmoreBTN' style={{ backgroundColor: headerData.primary_color }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setViewMoreFlag({
                                            flag: false, // or false depending on your logic
                                            section_title: '',
                                            content_img: ''
                                        });
                                    }}
                                >
                                    X
                                </button>
                            </span>
                            {viewMoreFlag.content_img && <img src={viewMoreFlag.content_img}
                                onClick={() => {
                                    setUrlData(viewMoreFlag.content_img);
                                    setImpagePreview(true);
                                }}></img>}
                            <div className="alt-text" dangerouslySetInnerHTML={{ __html: viewMoreFlag.section_title }} />
                        </div></div>}
                    {headerData && <div className="NavigationC">
                        <div className="mobile-menu" onClick={handleClick}>
                            {click ? (
                                <img className="menu-icon" src={Close}></img>
                            ) : (
                                <img className="menu-icon" src={Menu}></img>
                            )}
                        </div>

                        <img className="header_logo" src={headerData && headerData?.logo_img_url}
                            onClick={() => {
                                setClick(false);
                                setAutoplay(null);
                                setSelectedNavigationButton('saty')
                                setSelectedDropDownBtn('nanoHealth');
                                fetchpages(headerData.id, headerData.name)
                            }}></img>
                        <div className="navigation_link">
                            <div
                                style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'end', minWidth: '40vh', maxWidth: 'auto',
                                }}
                            >
                                {websiteData && websiteData.length >= 1 &&
                                    websiteData.filter(obj => obj?.w_section_type_master_id === 1)
                                        .map(filteredObj => (
                                            filteredObj?.content_details.map((section, index) => {
                                                if (index >= 3) return;
                                                return (
                                                    <span key={index} className="navigationBTN"
                                                        style={{
                                                            color: (selectNavButtom == section.service_detail_id
                                                                || selectNavButtom == section.action_path)
                                                                && currentPageName == section?.section_title?.replace(/\s+/g, '').toLowerCase() //Removed
                                                                ? headerData.primary_color : 'black'
                                                        }}
                                                        onClick={(e) => {
                                                            if (section.functionality_master_id == 3) {
                                                                setSelectedDropDownBtn('nanoHealth');
                                                                setSelectedNavigationButton(section.service_detail_id)
                                                                fetchpages(section.service_detail_id, section.section_title, 'page_id');
                                                                return
                                                            }
                                                            if (section.functionality_master_id == 22) {
                                                                if (isDashboardPage == false) {
                                                                    setSelectedNavigationButton('saty')
                                                                    setWLoader(true)
                                                                    fetchpages(headerData.id, headerData.name)
                                                                        .then(() => {
                                                                            setTimeout(() => {
                                                                                setSelectedNavigationButton(section.action_path);
                                                                                handleScrolltoSection(e, `#s-${section?.action_path}`);
                                                                                setWLoader(false);
                                                                            }, 1000)
                                                                        })
                                                                        .catch((error) => {
                                                                            console.error("Error fetching pages:", error);
                                                                        });
                                                                    return;
                                                                }
                                                                setSelectedNavigationButton(section.action_path)
                                                                handleScrolltoSection(e, `#s-${section?.action_path}`)
                                                            }
                                                        }}
                                                    >{section?.section_title}{" "}</span>
                                                );
                                            })
                                        ))
                                }


                                {websiteData && websiteData.length >= 1 &&
                                    websiteData.filter(obj => obj?.w_section_type_master_id === 1)[0]?.content_details?.length >= 4 &&
                                    <select
                                        className='NavigationSelect'
                                        style={{ color: selectNavButtom === SelectedDropDownBtn ? headerData.primary_color : 'black' }}
                                        value={SelectedDropDownBtn}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            const sectionId = e.target.value;
                                            const section = websiteData.filter(obj => obj?.w_section_type_master_id === 1)[0]
                                                .content_details.find(section => section.id === parseInt(sectionId));
                                            console.log("section on click navigation button ", e, section);
                                            if (section?.functionality_master_id === 3) {
                                                setSelectedDropDownBtn(sectionId);
                                                setSelectedNavigationButton(sectionId);
                                                fetchpages(section.service_detail_id, section.section_title, 'page_id');
                                            }
                                            if (section.functionality_master_id == 22) {
                                                if (isDashboardPage == false) {
                                                    setSelectedNavigationButton('saty');
                                                    setWLoader(true)
                                                    fetchpages(headerData.id, headerData.name)
                                                        .then(() => {
                                                            setTimeout(() => {
                                                                setSelectedNavigationButton(section.action_path);
                                                                handleScrolltoSection(e, `#s-${section?.action_path}`);
                                                                setWLoader(false)
                                                            }, 1000)
                                                        })
                                                        .catch((error) => {
                                                            console.error("Error fetching pages:", error);
                                                        });
                                                    return;
                                                }
                                                setSelectedDropDownBtn(sectionId);
                                                setSelectedNavigationButton(sectionId);
                                                setSelectedNavigationButton(section.action_path)
                                                handleScrolltoSection(e, `#s-${section?.action_path}`)
                                            }
                                        }}
                                    >
                                        <option className='NavigationOption' value={'nanoHealth'} >{'Other Solutions'}</option>
                                        {websiteData && websiteData.length >= 1 &&
                                            websiteData
                                                .filter(obj => obj?.w_section_type_master_id === 1)[0]
                                                .content_details.map((section, index) => {
                                                    if (index <= 2) return null;
                                                    return (
                                                        <option style={{ color: selectNavButtom === section.id ? headerData.primary_color : 'black' }}
                                                            className='NavigationOption'
                                                            key={index}
                                                            value={section.id} // Assuming `section.id` is a unique identifier
                                                        >
                                                            {section.section_title}
                                                        </option>
                                                    );
                                                })
                                        }
                                    </select>
                                }

                            </div>
                            <div
                                style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'end', minWidth: 'auto', maxWidth: 'auto',
                                }}
                            >
                                {headerData?.website_sections && headerData?.website_sections.length >= 1 &&
                                    headerData?.website_sections.filter(obj => obj?.w_section_type_master_id === 44).length > 0 &&
                                    headerData?.website_sections.filter(obj => obj?.w_section_type_master_id === 44)[0].content_details &&
                                    headerData?.website_sections.filter(obj => obj?.w_section_type_master_id === 44)[0].content_details.length > 0 &&
                                    headerData?.website_sections.filter(obj => obj?.w_section_type_master_id === 44)[0].content_details.map((section, index) => {
                                        return <div className='loginBTN' onClick={() => {
                                            setSelectedBtn(0);
                                            setOpenLogin(true)
                                        }}>{section.section_title}</div>
                                    })
                                }
                            </div>

                        </div>
                        <ul className={click ? "nav-options active" : "nav-options"} style={{ backgroundColor: 'rgb(0 0 0 / 57%)', height: '100vh' }}
                            onClick={() => { closeMobileMenu(); }}>
                            <div className="sideoptions_container" onClick={(e) => {
                                e.stopPropagation();
                            }}>
                                {websiteData && websiteData.length >= 1 &&
                                    websiteData.filter(obj => obj?.w_section_type_master_id === 1)
                                        .map(filteredObj => (
                                            filteredObj?.content_details.map((section, index) => {
                                                return (
                                                    <span key={index} className="option"
                                                        style={{
                                                            fontSize: '13px', color: selectNavButtom == section.service_detail_id ? 'white' : '#595959', cursor: 'pointer',
                                                            backgroundColor: selectNavButtom == section.service_detail_id ? headerData.primary_color : headerData.secondary_color
                                                        }}
                                                        onClick={(e) => {
                                                            // handleMouseEnter(index);
                                                            closeMobileMenu();
                                                            if (section.functionality_master_id == 3) {
                                                                setSelectedNavigationButton(section.service_detail_id)
                                                                fetchpages(section.service_detail_id, section.section_title, 'page_id');
                                                                return;
                                                            }
                                                            if (section.functionality_master_id == 22) {
                                                                if (isDashboardPage == false) {
                                                                    setSelectedNavigationButton('saty')
                                                                    setWLoader(true)
                                                                    fetchpages(headerData.id, headerData.name)
                                                                        .then(() => {
                                                                            setTimeout(() => {
                                                                                setSelectedNavigationButton(section.service_detail_id);
                                                                                handleScrolltoSection(e, `#s-${section?.action_path}`);
                                                                                setWLoader(false)
                                                                            }, 1000)
                                                                        })
                                                                        .catch((error) => {
                                                                            console.error("Error fetching pages:", error);
                                                                        });
                                                                    return;
                                                                }
                                                                setSelectedNavigationButton(section.service_detail_id)
                                                                handleScrolltoSection(e, `#s-${section?.action_path}`)
                                                            }
                                                        }}
                                                    >{section?.section_title}</span>
                                                );
                                            })
                                        ))
                                }
                            </div>
                        </ul>
                        <div className="extra"
                            onClick={() => {
                                if (headerData?.website_sections.filter(obj => obj?.w_section_type_master_id === 44).length > 0 && headerData?.website_sections.filter(obj => obj?.w_section_type_master_id === 44)[0].content_details &&
                                    headerData?.website_sections.filter(obj => obj?.w_section_type_master_id === 44)[0].content_details.length > 0) {
                                    setClick(false);
                                    setOpenLogin(true)
                                }
                            }}>
                            <img style={{ height: '40px', width: '40px', borderRadius: '50%' }}
                                src={'https://nanohealth-images.s3.ap-southeast-1.amazonaws.com/Profile_Icon.png'}></img>
                        </div>
                    </div>}
                    <div className="sectionExceptNavigation" ref={sectionRef}>

                        {websiteData && websiteData?.length >= 1
                            && websiteData.sort((a, b) => a.order_id - b.order_id).map((section, index) => {
                                if (section.w_section_type_master_id == 13) {
                                    return;
                                }
                                if (section.w_section_type_master_id == 28) {
                                    return (
                                        <div className="blog_container"
                                            style={{ backgroundColor: section.bg_color, color: headerData.primary_color }}
                                            key={index}
                                        >
                                            {section.section_name && <div
                                                className='bolderr section_name'
                                                style={{
                                                    color: headerData.primary_color, top: '-6px', margin: '0px 80px'
                                                }}>
                                                {section.section_name}
                                            </div>}
                                            <div className="blog_c_container">
                                                {sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                                    return (<div style={{ backgroundColor: obj.bg_color }}>
                                                        {selectedReadMore == 'null' && <div className="blog_content">
                                                            <div className="blog_c_left"><img alt='' src={obj.content_img}
                                                                onClick={() => {
                                                                    setUrlData(obj.content_img);
                                                                    setImpagePreview(true);
                                                                }}></img></div>
                                                            <div className="blog_c_right">
                                                                <div className='bolderr' style={{ color: headerData.primary_color, }}>{obj.section_title}</div>
                                                                <p className='bolderr'>{obj.content_heading}</p>
                                                                {obj.action_name && <button onClick={() => {
                                                                    readMoreClick(obj.section_title, obj.id);
                                                                    setSelectedReadMore(obj.id)
                                                                }}
                                                                    className="read_more" style={{ background: headerData.primary_color }}>{obj.action_name}</button>}
                                                            </div>
                                                        </div>}
                                                        {selectedReadMore == obj.id && <div>
                                                            <button className="read_moreC" style={{
                                                                background: headerData.primary_color, position: 'absolute', right: '160px',
                                                                top: '60px'
                                                            }}
                                                                onClick={() => { handleReadMoreClose() }}>Close</button>
                                                            <div className="mainBlog">
                                                                <img src={obj.content_img}
                                                                    onClick={() => {
                                                                        setUrlData(obj.content_img);
                                                                        setImpagePreview(true);
                                                                    }}></img>
                                                                <p className='bolderr'>{obj.content_heading}</p></div>
                                                            <div className="blog_body" dangerouslySetInnerHTML={{ __html: obj.content_body }}></div></div>}
                                                    </div>)
                                                })}</div>
                                        </div>
                                    )
                                } else if (section.w_section_type_master_id == 45 && section.content_details.length > 0) {
                                    return (
                                        <div
                                            style={{
                                                position: 'relative', backgroundColor: section.bg_color, alignItems: 'center',
                                                display: 'flex', justifyContent: 'center', color: '#4b89a8', height: 'auto', flexFlow: 'column'
                                            }}
                                            key={index}
                                        >
                                            {section.section_name && <div className='bolderr section_name'
                                                style={{
                                                    color: headerData.primary_color, margin: '0px 80px'
                                                }}>
                                                {section.section_name}
                                            </div>}
                                            <div className='htmlContent' style={{ color: 'black' }}>
                                                {sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                                    return (
                                                        <div dangerouslySetInnerHTML={{ __html: obj.content_body }}></div>)
                                                })}</div>
                                        </div>
                                    )
                                } else if (section.w_section_type_master_id == 27 && section.content_details.length > 0) {
                                    return (
                                        <div id={`s-${section.id}`}
                                            style={{
                                                position: 'relative', backgroundColor: section.bg_color,
                                            }}
                                            key={index}
                                        >
                                            {section.section_name && <span className='bolderr'
                                                style={{
                                                    paddingLeft: '5px', position: 'relative',
                                                    top: '8px', color: headerData.primary_color
                                                }}>
                                                {section.section_name}
                                            </span>}
                                            {sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                                return (
                                                    <div className="s-27c"
                                                        key={index} style={{
                                                            backgroundColor: obj.bg_color,
                                                            backgroundImage: `url(${obj.content_img})`,

                                                        }}
                                                    >
                                                        <div className="s-27cc" >
                                                            <div className="s-27form">
                                                                <input className="contactusinput" type="text" name="name" placeholder="Your Name" value={contactUs.name} onChange={handleChangeContactUS} />
                                                                <input className="contactusinput" type="number" name="mobile" placeholder="Mobile" value={contactUs.mobile} onChange={handleChangeContactUS} />
                                                                <textarea className="contactusinput" name="message" placeholder="Your Message" style={{ outline: 'none' }} value={contactUs.message} onChange={handleChangeContactUS} rows="6" cols="20" />
                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                                                                    <button className="nodeClickBtnC btnSize" style={{ backgroundColor: headerData.primary_color, margin: '10px', height: '30px' }} onClick={handleSubmit}>Submit</button></div>
                                                            </div>
                                                            <p style={{ margin: "10px 0px" }}><b>Or reach out to us directly at :</b></p>
                                                            {obj.content_body && <p style={{ fontSize: '13px' }}>
                                                                {obj.content_body.split('<b>').map((item, index) => {
                                                                    if (index !== 0) {
                                                                        const parts = item.split('</b>');
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <b className='bolderr'>{parts[0]}</b>{parts[1]}
                                                                            </React.Fragment>
                                                                        );
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                })}
                                                            </p>}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>)
                                } else if (section.w_section_type_master_id == 17 && section.content_details.length >= 0) {
                                    return (
                                        <div id={`s-${section.id}`}
                                            style={{
                                                position: 'relative', backgroundColor: section.bg_color,
                                                border: section?.id != null ? '1px solid rgb(0 0 0)' : '2px solid rgb(0 205 243)',
                                                display: 'flex', justifyContent: 'center', color: '#4b89a8', fontSize: 'x-small', minHeight: '15px'
                                            }}

                                            key={index}
                                        >
                                            {section.section_name}
                                        </div>
                                    )
                                } else if ((section.w_section_type_master_id == 2) && section.content_details.length > 0) {
                                    // return (
                                    //     <div id={`s-${section.id}`}
                                    //         style={{
                                    //             position: 'relative', backgroundColor: section.bg_color,
                                    //         }}
                                    //         key={index}
                                    //     >
                                    //         {/* {section.section_name && <div className='bolderr'
                                    //             style={{
                                    //                 paddingLeft: '5px', position: 'relative',
                                    //                 top: '8px', color: headerData.primary_color,
                                    //                 fontSize: 'x-large', textAlign: 'center', top: '2px',margin:'0px 78px'
                                    //             }}>
                                    //             {section.section_name}
                                    //         </div>} */}


                                    //         <ReactCaroussel slidesToShow={1} slidesToScroll={1} infinite={true}
                                    //             autoplay={true}    //need to update this to stop on hover over the section
                                    //             speed={6}
                                    //             display={{
                                    //                 dots: true,
                                    //                 arrows: section.content_details.length > 1 ? true : false,
                                    //             }}
                                    //         >
                                    //             {sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                    //                 return (
                                    //                     <div className="full_screen"
                                    //                         key={index}
                                    //                         style={{
                                    //                             backgroundColor: obj.bg_color, // Adjust as needed
                                    //                             backgroundImage: `url(${obj.content_img})`,
                                    //                             height: `${100 * (section?.section_ratio?.split(':').map(Number)[1])}vw`,
                                    //                         }}
                                    //                     >
                                    //                         <div style={{
                                    //                             height: 'inherit', backgroundColor: 'rgba(0,0,0,0.0)',
                                    //                             display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%',
                                    //                         }} >
                                    //                             <div className="slider_left_container">
                                    //                                 {obj.content_body && <div className="alt-texts" dangerouslySetInnerHTML={{ __html: obj.content_body }} />}
                                    //                                 {false && obj.content_body && <p className="fullscreen_cb">
                                    //                                     {obj.content_body.split('<b>').map((item, index) => {
                                    //                                         if (index !== 0) {
                                    //                                             const parts = item.split('</b>');
                                    //                                             return (
                                    //                                                 <React.Fragment key={index}>
                                    //                                                     <b className='bolderr'>{parts[0]}</b>{parts[1]}
                                    //                                                 </React.Fragment>
                                    //                                             );
                                    //                                         } else {
                                    //                                             return item;
                                    //                                         }
                                    //                                     })}
                                    //                                 </p>}
                                    //                                 {obj.action_name && <a className='nodeClickBtnC btnSize'
                                    //                                     onClick={() => {
                                    //                                         if (obj.action_path) {
                                    //                                             window.open(obj.action_path, "_blank")
                                    //                                         }
                                    //                                     }}
                                    //                                 >{obj.action_name}</a>}

                                    //                             </div>
                                    //                         </div>
                                    //                     </div>
                                    //                 );
                                    //             })}
                                    //         </ReactCaroussel>

                                    //     </div>)

                                    return (
                                        <div id={`s-${section.id}`}
                                            style={{
                                                position: 'relative', backgroundColor: section.bg_color,
                                            }}
                                            key={index}
                                        >
                                            {/* {section.section_name && <div className='bolderr'
                                            style={{
                                                paddingLeft: '5px', position: 'relative',
                                                top: '8px', color: headerData.primary_color,
                                                fontSize: 'x-large', textAlign: 'center', top: '2px',margin:'0px 78px'
                                            }}>
                                            {section.section_name}
                                        </div>} */}
                                            <ReactCaroussel slidesToShow={1} slidesToScroll={1} infinite={true}
                                                autoplay={true}    //need to update this to stop on hover over the section
                                                speed={5}
                                                display={{
                                                    // dots: section.content_details.length > 1 ? true : false,
                                                    arrows: section.content_details.length > 1 ? true : false,
                                                }}
                                            >
                                                {(section.content_details.length > 1
                                                    ? [...Array(50)].flatMap(() => sortArrayByOrderId([...section.content_details]))
                                                    : sortArrayByOrderId([...section.content_details])
                                                ).map((obj, index) => {
                                                    return (
                                                        <div className="full_screen"
                                                            key={index}
                                                            style={{
                                                                backgroundColor: obj.bg_color, // Adjust as needed
                                                                backgroundImage: `url(${obj.content_img})`,
                                                                height: `${100 * (section?.section_ratio?.split(':').map(Number)[1])}vw`,
                                                            }}
                                                        >
                                                            <div style={{
                                                                height: 'inherit', backgroundColor: 'rgba(0,0,0,0.0)',
                                                                display: 'flex',
                                                                // justifyContent: 'start', alignItems: 'center',
                                                                width: '100%',
                                                            }} >
                                                                <div className="slider_left_container" style={{ position: 'relative', marginLeft: '0px' }}>
                                                                    {obj.content_body && <div className="alt-texts"
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: `${getContentBodyValue(obj.content_body).y}%`,
                                                                            left: `${getContentBodyValue(obj.content_body).x > 40 ? getContentBodyValue(obj.content_body).x + 10 : getContentBodyValue(obj.content_body).x}%`,
                                                                            // transform: "translate(-50%, -50%)",
                                                                        }} dangerouslySetInnerHTML={{ __html: getContentBodyValue(obj.content_body).value }} />}
                                                                    {obj.action_name && <a className='nodeClickBtnC btnSize' style={{ position: 'absolute', top: '50%' }}
                                                                        onClick={() => {
                                                                            if (obj.action_path) {
                                                                                window.open(obj.action_path, "_blank")
                                                                            }
                                                                        }}
                                                                    >{obj.action_name}</a>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </ReactCaroussel>
                                        </div>
                                    );

                                } else if ((section.w_section_type_master_id == 26) && section.content_details.length > 0) {
                                    return (
                                        <div id={`s-${section.id}`} className={`sc-${section.w_section_type_master_id}`}
                                            style={{
                                                position: 'relative', backgroundColor: section.bg_color,
                                                marginBottom: '18px',

                                            }}
                                            key={index}
                                        >
                                            {section.section_name && <div
                                                className='bolderr section_name'
                                                style={{
                                                    color: headerData.primary_color, top: '0px', margin: '0px', marginBottom: '10px'
                                                }}>
                                                {section.section_name}
                                            </div>}
                                            {sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                                return (
                                                    <div className={`scContent-${section.w_section_type_master_id}`} style={{ flexDirection: index % 2 === 0 ? 'row' : 'row-reverse', backgroundColor: obj.bg_color, marginBottom: '10px' }}>
                                                        <div style={{ flexBasis: '70%', height: '100%' }}>
                                                            <p className="bolderr" style={{ fontSize: '17px', fontWeight: '300', fontFamily: '"Lato", sans-serif', marginBottom: '5px' }}> {<b>{obj.content_heading}</b>}</p>
                                                            <p className={`contentText-${section.w_section_type_master_id}`} style={{ fontSize: '13px', color: 'black', height: 'auto', marginRight: '100px' }}>{obj.content_body}</p>
                                                        </div>
                                                        <div className={index % 2 == 0 ? 'marginL5' : 'marginR50'} style={{
                                                            // marginLeft: index % 2 === 0 ? '5px' : '0',
                                                            // marginRight: index % 2 !== 0 ? '50px' : '0',
                                                            flexBasis: '30%', height: '100%'
                                                        }}>
                                                            <img src={obj.content_img} style={{ height: 'inherit', width: '100%', borderRadius: '5px' }}
                                                                onClick={() => {
                                                                    setUrlData(obj.content_img);
                                                                    setImpagePreview(true);
                                                                }}></img>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>)

                                } else if ((section.w_section_type_master_id == 35) && section.content_details.length > 0) {
                                    return (
                                        <div id={`s-${section.id}`}
                                            style={{
                                                position: 'relative', backgroundColor: section.bg_color,
                                                border: section?.content_details.length == 0 ? '2px solid rgb(0 205 243)' : ''
                                            }}
                                            key={index}
                                        >
                                            {section.section_name && <div
                                                className='bolderr section_name'
                                                style={{
                                                    color: headerData.primary_color, margin: '0px 80px',
                                                }}>
                                                {section.section_name}
                                            </div>}
                                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: 'auto' }}>
                                                {sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                                    return (
                                                        <div
                                                            key={index} style={{
                                                                minHeight: '20vh', width: '30vh', borderRadius: '6px', margin: '0px 80px',
                                                                display: "flex", boxShadow: 'rgba(0, 0, 0, 0.0025) 0.1px 0.9px 5px',
                                                                alignItems: "center", justifyContent: "space-around",
                                                                overflow: 'hidden',
                                                                //  border: '1px solid rgb(14 111 162)',
                                                                margin: '20px 2.7px',
                                                                backgroundColor: obj.bg_color // Adjust as needed
                                                                // backgroundImage: `url(${obj.content_img})`,
                                                                // backgroundSize: 'cover'

                                                            }}
                                                        >
                                                            <div style={{
                                                                height: 'inherit', backgroundColor: 'rgba(0,0,0,0.0)',
                                                                display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'inherit',
                                                            }} >
                                                                <div style={{
                                                                    marginLeft: '0px', display: 'flex', alignItems: 'center',
                                                                    justifyContent: 'center', flexFlow: 'column'
                                                                }}>

                                                                    <p style={{ fontSize: '38px', color: headerData.primary_color, }}>{obj.content_body}</p>
                                                                    <p style={{ fontSize: '13px', latterBreak: 'anywhere', height: '20px' }}> {obj.content_heading}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>)

                                } else if ((section.w_section_type_master_id == 6) && section.content_details.length > 0) {
                                    return (
                                        <div id={`s-${section.id}`} className={`sc-${section.w_section_type_master_id}`}
                                            style={{
                                                position: 'relative', backgroundColor: section.bg_color,
                                                border: section?.content_details.length == 0 ? '2px solid rgb(0 205 243)' : ''
                                            }}
                                            key={index}
                                        >
                                            {section.section_name && <div
                                                className='bolderr section_name'
                                                style={{
                                                    color: headerData.primary_color, top: '0px', marginBottom: "10px"
                                                }}>
                                                {section.section_name}
                                            </div>}
                                            <div className={`scontent-${section.w_section_type_master_id}`}
                                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: 'auto' }}>
                                                {sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                                    return (
                                                        <div className={`scTotal-${section.w_section_type_master_id}`}
                                                            key={index} style={{
                                                                backgroundColor: obj.bg_color,
                                                            }}

                                                        >
                                                            <div style={{
                                                                height: 'inherit',
                                                                display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'inherit',
                                                            }} >
                                                                <div style={{
                                                                    marginLeft: '0px', display: 'flex', alignItems: 'center',
                                                                    justifyContent: 'center', width: '95%', flexFlow: 'column',
                                                                }} >
                                                                    <img src={obj.content_img}
                                                                        onClick={() => {
                                                                            setUrlData(obj.content_img);
                                                                            setImpagePreview(true);
                                                                        }} className={`scContent-${section.w_section_type_master_id}`} style={{ height: '171px', width: '171px', borderRadius: '10%' }}></img>
                                                                    <p className={`scFont-${section.w_section_type_master_id}`}> {<b> {obj.content_heading} </b>}</p>
                                                                    <p className={`scfontpara-${section.w_section_type_master_id}`} >{obj.content_body}</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>)

                                } else if ((section.w_section_type_master_id == 5) && section.content_details.length > 0) {
                                    return (
                                        <div id={`s-${section.id}`}
                                            className={`se-${section.w_section_type_master_id}`}
                                            style={{
                                                backgroundColor: section.bg_color,
                                                border: section?.content_details.length == 0 ? '2px solid rgb(0 205 243)' : ''
                                            }}
                                            key={index}
                                        > {section.section_name && <div
                                            className='bolderr section_name'
                                            style={{
                                                color: headerData.primary_color, top: '-6px', margin: '0px 0px', marginBottom: "10px"
                                            }}>
                                            {section.section_name}
                                        </div>}
                                            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '55vh' }}> */}
                                            <ReactCaroussel className={`scaro-${section.w_section_type_master_id}`}
                                                slidesToShow={slidesToShow}
                                                slidesToScroll={1} infinite={true}
                                                autoplay={true}
                                                speed={6}
                                                display={{
                                                    dots: !true,
                                                    arrows: true,
                                                }}>
                                                {sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                                    return (
                                                        <div className={`scontent-${section.w_section_type_master_id}`}
                                                            key={index} style={{

                                                                backgroundColor: obj.bg_color,
                                                                backgroundImage: `linear-gradient(to right bottom, rgb(0 191 127 / 20%), rgb(171 0 3 / 20%))`,

                                                            }}
                                                        >
                                                            <div style={{
                                                                height: 'inherit',
                                                                display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'inherit',
                                                            }} >
                                                                <div style={{
                                                                    marginLeft: '0px', display: 'flex',
                                                                    alignContent: 'space-between', width: '95%', flexWrap: 'wrap', height: '100%', padding: '10px'
                                                                }}>
                                                                    <p style={{ fontSize: '15px', color: 'black', height: 'auto' }}>{obj.content_body}</p>
                                                                    <div style={{ width: 'inherit', display: 'flex', flexFlow: 'row' }}>
                                                                        {obj.content_img && <img src={obj.content_img}
                                                                            onClick={() => {
                                                                                setUrlData(obj.content_img);
                                                                                setImpagePreview(true);
                                                                            }}
                                                                            style={{ height: '40px', width: '40px', marginRight: '10px' }}></img>}
                                                                        <div>
                                                                            <p style={{ fontSize: '12px', marginBottom: '0px' }}> {obj.content_heading}</p>
                                                                            <p style={{ fontSize: '8px', marginBottom: '0px' }}> {obj.action_name}</p>
                                                                            <p style={{ fontSize: '8px', marginBottom: '0px' }}>
                                                                                {Array.from({ length: obj.action_path <= 5 ? obj.action_path : 5 }).map((_, idx) => (
                                                                                    <span
                                                                                        key={idx}
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                            color: 'gold',
                                                                                            fontSize: '14px',
                                                                                            textShadow: '1px 1px 1px black',
                                                                                        }}
                                                                                    >
                                                                                        ★
                                                                                    </span>
                                                                                ))}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </ReactCaroussel>
                                            {/* </div> */}
                                        </div>)

                                }

                                else if (section.w_section_type_master_id == 11) {
                                    // return;
                                    return (
                                        <div id={`s-${section.id}`}
                                            style={{
                                                position: 'relative', backgroundColor: headerData.secondary_color, height: '120px',
                                                border: section?.content_details.length == 0 ? '2px solid rgb(0 205 243)' : ''
                                            }}
                                            key={index}
                                        >
                                            {section.section_name && <span className='bolderr'
                                                style={{
                                                    paddingLeft: '5px', position: 'relative',
                                                    top: '8px', color: headerData.primary_color,
                                                    fontSize: 'x-large', marginLeft: '50px'
                                                }}>
                                                {section.section_name}
                                            </span>}
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '14vh' }}>
                                                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap', color: 'white', width: '50%', margin: '0% 5%', marginTop: '10px' }}>
                                                    <p className='bolderr' style={{ fontSize: '18px', margin: '0px' }}>Useful Links :{" "}</p>
                                                    {section.content_details.filter(obj => obj.content_heading === "follow_lissnks" || obj.content_heading === "footer_links" || obj.content_heading === "contact_mail").map((obj, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                            >
                                                                <span style={{ fontSize: '16px', display: 'flex', alignItems: 'center', height: '14px', whiteSpace: 'pre' }}>
                                                                    {`${" "}${obj.action_name}${" "}|${" "}`}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap', color: 'white', width: '35%', marginRight: '5%', marginTop: '5px' }}>
                                                    <p className={`bolderr`}
                                                        style={{ fontSize: '18px', margin: '0px' }}>Follow Us :{" "}</p>
                                                    {section.content_details.filter(obj => obj.content_heading === "follow_links").map((obj, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                            >
                                                                <img style={{ height: '35px', margin: '2px' }} src={obj.content_img}
                                                                    onClick={() => {
                                                                        setUrlData(obj.content_img);
                                                                        setImpagePreview(true);
                                                                    }}
                                                                    alt="" />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div style={{
                                                display: 'flex', justifyContent: 'space-around', borderTop: `1px solid ${headerData.theme_color}`, paddingTop: '5px',
                                                alignItems: 'center', height: 'auto', backgroundColor: headerData.secondary_color, color: 'white'
                                            }}>
                                                {section?.content_details?.filter(obj => obj.content_heading != "follow_links"
                                                    && obj.content_heading != "footer_links" && obj.content_heading !== "contact_mail").map((obj, index) => {
                                                        if (index == 0) {
                                                            return (
                                                                <>
                                                                    <div
                                                                        key={index}
                                                                    >
                                                                        <span style={{ fontSize: '16px' }}>{obj.action_name}</span>
                                                                    </div>
                                                                    <div
                                                                        key={index}
                                                                    >
                                                                        <span style={{ fontSize: '16px' }}>{`Copyright${" "}@${" "}2024${" "}All${" "}Rights${" "}Reserved${" "}|${" "}${headerData.name}`}</span>
                                                                    </div>
                                                                </>
                                                            );
                                                        } else {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                >
                                                                    <span style={{ fontSize: '16px' }}>{obj.action_name}</span>
                                                                </div>
                                                            );
                                                        }

                                                    })}
                                            </div>
                                        </div>)

                                } else if ((section.w_section_type_master_id == 13) && section.content_details.length > 0) {
                                    return (
                                        <div id={`s-${section.id}`}
                                            style={{

                                                position: 'relative', backgroundColor: section.bg_color,
                                                border: section?.content_details.length == 0 ? '2px solid rgb(0 205 243)' : ''
                                            }}
                                            key={index}
                                        >
                                            {section.section_name && <span className='bolderr'
                                                style={{
                                                    paddingLeft: '5px', position: 'relative',
                                                    top: '8px', color: headerData.primary_color,
                                                    fontSize: 'x-large', marginLeft: '50px'
                                                }}>
                                                {section.section_name}
                                            </span>}
                                            <ReactCaroussel slidesToShow={1} slidesToScroll={1} infinite={true}>
                                                {sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                                    return (
                                                        <div
                                                            key={index} style={{
                                                                height: '40vh', width: '100vh', borderRadius: '6px',
                                                                display: "flex", boxShadow: 'rgba(0, 0, 0, 0.0025) 0.1px 0.9px 5px',
                                                                alignItems: "center", justifyContent: "space-around",
                                                                overflow: 'hidden', border: '1px solid rgb(14 111 162)',
                                                                margin: '0px 2.7px',
                                                                backgroundColor: obj.bg_color // Adjust as needed
                                                                // backgroundImage: `url(${obj.content_img})`,
                                                                // backgroundSize: 'cover'

                                                            }}

                                                        >
                                                            <div style={{
                                                                height: 'inherit', backgroundColor: 'rgba(0,0,0,0.0)',
                                                                display: 'flex', justifyContent: 'start', alignItems: 'center', width: 'inherit',
                                                            }} >
                                                                <div style={{
                                                                    marginLeft: '40px', display: 'flex', alignItems: 'center',
                                                                    justifyContent: 'start', width: '90%', flexWrap: 'wrap'
                                                                }}>

                                                                    <p className='bolderr' style={{ color: 'black' }}> {obj.content_heading}</p>
                                                                    <p style={{ fontSize: '13px' }}> {obj.content_body || obj.desc}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </ReactCaroussel>
                                        </div>)

                                } else if (section.w_section_type_master_id == 25 && section.content_details.length > 0) {
                                    return (
                                        <div id={`s-${section.id}`}
                                            className={`s-${section.w_section_type_master_id}`}
                                            style={{
                                                backgroundColor: section.bg_color == '#ffffff' ? headerData.secondary_color : section.bg_color,
                                            }}
                                            key={index}
                                            onMouseEnter={() => {
                                                const element = document.querySelector(`.cc-${section.w_section_type_master_id}`);
                                                if (element) {
                                                    element.style.animationPlayState = 'paused';
                                                }
                                            }}
                                            onMouseLeave={() => {
                                                const element = document.querySelector(`.cc-${section.w_section_type_master_id}`);
                                                if (element) {
                                                    element.style.animationPlayState = 'running';
                                                }
                                            }}
                                        >
                                            {section.section_name && <div
                                                className='bolderr section_name'
                                                style={{
                                                    color: headerData.primary_color, margin: '0px 80px'
                                                }}>
                                                {section.section_name}
                                            </div>}

                                            <div className={`cc-${section.w_section_type_master_id} ${section.content_details.length > 4 ? 'sliding-text' : ''}`} >
                                                {sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            style={{ backgroundColor: 'transparent' }}
                                                        >
                                                            <div style={{
                                                                height: 'inherit',
                                                                display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'inherit',
                                                            }} >
                                                                <div style={{
                                                                    marginLeft: '0px', display: 'flex', alignItems: 'center',
                                                                    justifyContent: 'center', width: '95%', flexFlow: 'column',
                                                                }}>
                                                                    <div>
                                                                        <img className="img25" src={obj.content_img}
                                                                        // onClick={() => {
                                                                        //     setUrlData(obj.content_img);
                                                                        //     setImpagePreview(true);
                                                                        // }}
                                                                        ></img>
                                                                        {obj.content_heading && <p style={{ fontSize: '12px', }}> {obj.content_heading}</p>}
                                                                    </div>
                                                                    {obj.content_body && <p style={{ fontSize: '8px', color: 'black', height: '45px' }}>{obj.content_body}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {section?.content_details?.length > 4 && sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                                    return (
                                                        <div key={index} >
                                                            <div style={{
                                                                height: 'inherit',
                                                                display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'inherit',
                                                            }} >
                                                                <div style={{
                                                                    marginLeft: '0px', display: 'flex', alignItems: 'center',
                                                                    justifyContent: 'center', width: '95%', flexFlow: 'column',
                                                                }}>
                                                                    <div>
                                                                        <img className="img25" src={obj.content_img}
                                                                        // onClick={() => {
                                                                        //     setUrlData(obj.content_img);
                                                                        //     setImpagePreview(true);
                                                                        // }}
                                                                        ></img>
                                                                        {obj.content_heading && <p style={{ fontSize: '12px', }}> {obj.content_heading}</p>}
                                                                    </div>
                                                                    {obj.content_body && <p style={{ fontSize: '8px', color: 'black', height: '45px' }}>{obj.content_body}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>)
                                } else if (section.w_section_type_master_id == 38 && section.content_details.length > 0) {
                                    return (
                                        <div id={`s-${section.id}`} className="Largeimage38"
                                            style={{
                                                position: 'relative', backgroundColor: section.bg_color,
                                                border: section?.content_details.length == 0 ? '2px solid rgb(0 205 243)' : '', minHeight: '50vh'
                                            }}
                                            key={index}
                                        >
                                            {section.section_name && <div className='bolderr'
                                                style={{
                                                    paddingLeft: '5px', position: 'relative', margin: '0px 78px',
                                                    top: '8px', color: headerData.primary_color,
                                                    fontSize: 'x-large', marginLeft: '50px', textAlign: 'center', marginBottom: "10px"
                                                }}>
                                                {section.section_name}
                                            </div>}

                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', flexFlow: 'column' }}>
                                                {sortArrayByOrderId([...section.content_details]).map((obj, index) => {
                                                    return (
                                                        <div key={index} style={{
                                                            height: 'inherit', width: '90%', borderRadius: '6px',
                                                            display: "flex", boxShadow: 'rgba(0, 0, 0, 0.0025) 0.1px 0.9px 5px',
                                                            alignItems: "center", justifyContent: "space-around",
                                                            overflow: 'hidden',
                                                            // border: '1px solid rgb(14 111 162)',
                                                            margin: '0px 2.7px', backgroundSize: 'cover',
                                                            backgroundColor: obj.bg_color,
                                                            margin: '10px'

                                                        }}
                                                        >

                                                            <img src={obj.content_img} className="Largeimageimage" style={{ height: '70vh' }}
                                                                onClick={() => {
                                                                    setUrlData(obj.content_img);
                                                                    setImpagePreview(true);
                                                                }}></img>

                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>)
                                } else if (section.w_section_type_master_id == 42 && section.content_details.length > 0) {
                                    return (
                                        <div
                                            id={`s-${section.id}`}
                                            style={{
                                                position: 'relative', backgroundColor: section.bg_color,
                                                border: section?.content_details.length == 0 ? '2px solid rgb(0 205 243)' : '', height: 'auto'
                                            }}
                                            key={index}>
                                            {section.section_name && <div className='bolderr section_name'
                                                style={{
                                                    color: headerData.primary_color, margin: '0px 80px'
                                                }}>
                                                {section.section_name}
                                            </div>}
                                            <div className="recommendation-container">
                                                {sortArrayByOrderId([...section.content_details]).map((obj, index) => (
                                                    <div className="recommendation-item" style={{ backgroundColor: obj.bg_color, borderRadius: '6px' }}
                                                        key={index}
                                                        onClick={(() => {

                                                            // For content redirection
                                                            console.log("----------objobj----------", obj)
                                                            if (obj.functionality_master_id === 3) {
                                                                setSelectedDropDownBtn('nanoHealth');
                                                                setSelectedNavigationButton(obj.service_detail_id);
                                                                const pathName = obj.section_title.toLowerCase().replace(/\s+/g, '-');
                                                                navigate(`/${pathName}/${encrypt(obj.service_detail_id)}`);
                                                                fetchpages(obj.service_detail_id, obj.section_title, 'page_id');
                                                                return;
                                                            }

                                                            // For external links
                                                            // if (obj.action_path === 'Link') {
                                                            //     window.open(obj.section_title, '_blank');
                                                            //     return;
                                                            // }

                                                            // For modal content
                                                            //    else if(obj.action_path === 'Modal') {
                                                            //         setViewMoreFlag({
                                                            //             flag: true,
                                                            //             section_title: obj.section_title,
                                                            //             content_img: obj.content_img,
                                                            //         });
                                                            //     }
                                                        })}


                                                    // onClick={(() => {
                                                    //         console.log("section.functionality_master_id:", section.functionality_master_id);
                                                    //         console.log("obj.action_path:", obj.action_path);
                                                    //         console.log("obj.section_title:", obj.section_title);

                                                    //         if (section.functionality_master_id == 3) {
                                                    //             setSelectedDropDownBtn('nanoHealth');
                                                    //             setSelectedNavigationButton(section.service_detail_id)
                                                    //             fetchpages(section.service_detail_id, section.section_title, 'page_id');
                                                    //             return
                                                    //         }
                                                    //         if (obj.action_path == 'Link') {
                                                    //             console.log("Redirecting to:", obj.section_title);
                                                    //             window.location.href = obj.section_title;
                                                    //             // window.open(obj.section_title);
                                                    //         } else if (obj.action_path == 'Modal') {
                                                    //             setViewMoreFlag({
                                                    //                 flag: true,
                                                    //                 section_title: obj.section_title,
                                                    //                 content_img: obj.content_img,
                                                    //             });

                                                    //         }
                                                    //     })}

                                                    >
                                                        <h1 id="rH1">{obj.content_heading}</h1>
                                                        <p id='rP'>{obj.content_body}</p>
                                                        {obj.action_name && <span id="viewMore" style={{ color: headerData.primary_color }}
                                                            onClick={(() => {

                                                                console.log("section.functionality_master_id:", section.functionality_master_id);
                                                                console.log("obj.action_path:", obj.action_path);
                                                                console.log("obj.section_title:", obj.section_title);

                                                                // if (section.functionality_master_id == 3) {
                                                                //     setSelectedDropDownBtn('nanoHealth');
                                                                //     setSelectedNavigationButton(section.service_detail_id)
                                                                //     fetchpages(section.service_detail_id, section.section_title, 'page_id');
                                                                //     return
                                                                // }
                                                                // // if (obj.action_path == 'Link') {
                                                                // //     console.log("Redirecting to:", obj.section_title);
                                                                // //     window.location.href = obj.section_title;
                                                                // //     // window.open(obj.section_title);
                                                                // // } 
                                                                if (obj.action_path == 'Modal') {
                                                                    setViewMoreFlag({
                                                                        flag: true,
                                                                        section_title: obj.section_title,
                                                                        content_img: obj.content_img,
                                                                    });

                                                                }
                                                            })}
                                                        >
                                                            {obj.action_name} &raquo;</span>}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    );
                                } else if (section.w_section_type_master_id == 43 && section.content_details.length > 0) {
                                    return (
                                        <div id={`s-${section.id}`}
                                            style={{
                                                position: 'relative',
                                                backgroundColor: section.bg_color,
                                                border: section?.content_details.length == 0 ? '2px solid rgb(0 205 243)' : '',
                                                height: 'auto',
                                                paddingBottom: '15px'
                                            }}

                                            key={index}>

                                            {section.section_name && <div className='bolderr section_name'
                                                style={{
                                                    color: headerData.primary_color, margin: '0px 80px',
                                                }}>
                                                {section.section_name}
                                            </div>}



                                            <div className="slider-container">
                                                <ReactCaroussel
                                                    slidesToShow={1}
                                                    slidesToScroll={1}
                                                    infinite={true}
                                                    autoplay={true}
                                                    speed={6}
                                                    display={{
                                                        dots: section.content_details.length > 1 ? true : false,
                                                        arrows: section.content_details.length > 1 ? true : false,
                                                    }}
                                                >
                                                    {sortArrayByOrderId([...section.content_details]).map((obj, index) => (
                                                        <div key={index} className="slide" style={{ backgroundColor: obj.bg_color }}>
                                                            <div className="useCaseimg" style={{ flex: 1 }}>
                                                                <img
                                                                    src={obj.content_img}
                                                                    style={{ height: '100%', width: 'auto' }}
                                                                    onClick={() => {
                                                                        setUrlData(obj.content_img);
                                                                        setImpagePreview(true);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div
                                                                className="alt-text"
                                                                dangerouslySetInnerHTML={{ __html: obj.content_body }}
                                                            />
                                                        </div>
                                                    ))}
                                                </ReactCaroussel>
                                            </div>


                                        </div>
                                    );
                                } else if ((section.w_section_type_master_id == 7) && section.content_details.length > 0) {
                                    return (
                                        <div id={`s-${section.id}`}
                                            style={{
                                                position: 'relative', backgroundColor: 'white',
                                                border: section?.content_details.length == 0 ? '2px solid rgb(0 205 243)' : '',
                                                borderTop: `2.5px solid ${headerData.primary_color}`
                                            }}
                                            key={index}
                                        >

                                            {section.section_name && <span className='bolderr'
                                                style={{
                                                    paddingLeft: '5px', position: 'relative',
                                                    top: '8px', color: headerData.primary_color,
                                                }}>
                                                {section.section_name}
                                            </span>}
                                            <div className="footer_content">
                                                <div style={{
                                                    display: 'flex', justifyContent: 'start', alignItems: 'center', flexFlow: 'column', color: 'white', width: '31%',
                                                    marginTop: '5px'
                                                }}>
                                                    <div className={`bolderr`}
                                                        style={{
                                                            fontSize: '15px', marginBottom: '3px', width: '100%', color: 'black', height: '35px',
                                                            borderBottom: `2px solid ${headerData.primary_color}`
                                                        }}>
                                                        <img className="footerlogo" src={headerData.logo_img_url}
                                                            onClick={() => {
                                                                setUrlData(headerData.logo_img_url);
                                                                setImpagePreview(true);
                                                            }}></img>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', padding: '0px 5px' }}>
                                                        {section.content_details.filter(obj => obj.content_heading === "footer_links").map((obj, index) => {
                                                            return (
                                                                <div key={index} className="margin5 padding5" >
                                                                    <span style={{
                                                                        fontSize: '8px', display: 'flex', alignItems: 'center', height: '14px', whiteSpace: 'pre',
                                                                        color: selectNavButtom == obj.service_detail_id ? headerData.primary_color : 'black'
                                                                    }}
                                                                        onClick={(e) => {
                                                                            handleMouseEnter(index);
                                                                            closeMobileMenu();
                                                                            if (obj.functionality_master_id == 3) {
                                                                                setSelectedNavigationButton(obj.service_detail_id)
                                                                                fetchpages(obj.service_detail_id, obj.action_name, 'page_id');  //Here update required
                                                                                return;
                                                                            }
                                                                            if (obj.functionality_master_id == 22) {
                                                                                if (isDashboardPage == false) {
                                                                                    setSelectedNavigationButton('saty')
                                                                                    setWLoader(true)
                                                                                    fetchpages(headerData.id, headerData.name)
                                                                                        .then(() => {
                                                                                            setTimeout(() => {
                                                                                                setSelectedNavigationButton(obj.service_detail_id);
                                                                                                handleScrolltoSection(e, `#s-${obj?.action_path}`);
                                                                                                setWLoader(false)
                                                                                            }, 1000)
                                                                                        })
                                                                                        .catch((error) => {
                                                                                            console.error("Error fetching pages:", error);
                                                                                        });
                                                                                    return;
                                                                                }
                                                                                setSelectedNavigationButton(obj.service_detail_id)
                                                                                handleScrolltoSection(e, `#s-${obj?.action_path}`)
                                                                            }
                                                                        }}>
                                                                        {`${" "}${obj.action_name}${" "}`}
                                                                    </span>
                                                                </div>
                                                            );
                                                        })}</div>
                                                </div>

                                                <div style={{
                                                    display: 'flex', justifyContent: 'start', alignItems: 'center', flexFlow: 'column', color: 'white', minWidth: '24%',
                                                    marginTop: '5px'
                                                }}>
                                                    <div className={`bolderr`}
                                                        style={{
                                                            fontSize: '15px', marginBottom: '3px', width: '100%', color: 'black', height: '35px',
                                                            borderBottom: `2px solid ${headerData.primary_color}`
                                                        }}>Contacts Us{" "}
                                                    </div>
                                                    <div className="padding5" style={{ width: '100%' }}>
                                                        {section.content_details.filter(obj => obj.content_heading === "contact_mail" || obj.content_heading === 'contact_number').map((obj, index) => {
                                                            return (
                                                                <div key={index} className="margin5"
                                                                    onClick={() => {
                                                                        if (obj.action_name !== null && obj.content_heading === 'contact_mail') {
                                                                            window.location.href = `mailto:${obj.action_name}`;
                                                                        }
                                                                    }}>
                                                                    <span style={{ fontSize: '8px', display: 'flex', alignItems: 'center', height: '14px', whiteSpace: 'pre', color: 'black' }}>
                                                                        <span className={`bolderr`} style={{ fontSize: '8px', }}
                                                                        >{obj.content_heading === 'contact_number' ? `Mobile:` : obj.content_heading === 'contact_mail' ? `Email:` : ""}</span> {`${" "}${obj.action_name}${" "}`}
                                                                    </span>
                                                                </div>
                                                            );
                                                        })}</div>
                                                </div>

                                                <div style={{
                                                    display: 'flex', justifyContent: 'start', alignItems: 'start', flexFlow: 'column', color: 'white',
                                                    marginTop: '5px'
                                                }}>
                                                    <div className={`bolderr`}
                                                        style={{
                                                            fontSize: '15px', marginBottom: '3px', width: '100%', color: 'black', height: '35px',
                                                            borderBottom: `2px solid ${headerData.primary_color}`
                                                        }}>Follow Us{" "}
                                                    </div>
                                                    <div className="padding5" style={{ display: 'flex' }}>
                                                        {section.content_details.filter(obj => obj.content_heading === "follow_links").map((obj, index) => {
                                                            return (
                                                                <div key={index} className="margin5" >
                                                                    <img className="footer_img" src={obj.content_img} alt=""
                                                                        onClick={() => {
                                                                            if (obj.action_path) {
                                                                                window.open(obj.action_path, "_blank")
                                                                            }
                                                                        }} />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="footer_text"
                                                style={{
                                                    backgroundColor: headerData.primary_color,
                                                }}>
                                                {section?.content_details?.filter(obj => obj.content_heading != "follow_links" && obj.content_heading != "contact_number"
                                                    && obj.content_heading != "footer_links" && obj.content_heading !== "contact_mail").map((obj, index) => {
                                                        if (index == 0) {
                                                            return (
                                                                <>
                                                                    <div key={index} >
                                                                        <span style={{ fontSize: '8px', cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                if (obj.action_path) {
                                                                                    window.open(obj.action_path, "_blank")
                                                                                }
                                                                            }}>{obj.action_name}</span>
                                                                    </div>
                                                                    <div
                                                                        key={index}
                                                                    >
                                                                        <span style={{ fontSize: '8px' }}>{`Copyright${" "}@${" "}2024${" "}All${" "}Rights${" "}Reserved${" "}|${" "}${headerData.name}`}</span>
                                                                    </div>
                                                                </>
                                                            );
                                                        } else {
                                                            return (
                                                                <div key={index}>
                                                                    <span style={{ fontSize: '8px', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            if (obj.action_path) {
                                                                                window.open(obj.action_path, "_blank")
                                                                            }
                                                                        }}>{obj.action_name}</span>
                                                                </div>
                                                            );
                                                        }

                                                    })}
                                            </div>
                                        </div>)

                                } else if (section.w_section_type_master_id == 55) {
                                    return (
                                        <div
                                            id={`s-${section.id}`}
                                            style={{
                                                position: 'relative',
                                                backgroundColor: section.bg_color,
                                                border: section?.content_details.length === 0 ? '2px solid rgb(0 205 243)' : '',
                                                height: 'auto',
                                            }}
                                            key={index}
                                        >
                                            {section.section_name && (
                                                <div
                                                    className='bolderr section_name'
                                                    style={{
                                                        color: headerData.primary_color,
                                                        margin: '0px 80px',
                                                        top: '10px',
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    {section.section_name}
                                                </div>
                                            )}
                                            <div
                                                className="recommendation-container"
                                                style={{
                                                    padding: '15px 150px',
                                                    width: '100%',
                                                    display: section.content_details.length < 4 ? 'flex' : 'grid',
                                                    gridTemplateColumns: 'repeat(4, 250px)',
                                                    gap: '40px',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {sortArrayByOrderId([...section.content_details]).map((obj, index) => (
                                                    <div id="bg_contain"
                                                        style={{
                                                            background: `url(${obj.content_img})`,
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            borderRadius: '8%',
                                                            backgroundSize: '100% 100%',
                                                            height: '250px',
                                                            width: '250px',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            position: 'relative',
                                                            cursor: 'pointer',
                                                            boxShadow: 'none',
                                                        }}
                                                        className="recommendation-item"
                                                        key={index}
                                                        onClick={() => setModalContent({
                                                            isOpen: true,
                                                            title: obj.section_title,
                                                            description: obj.content_body || 'No description available',
                                                            image: obj.content_img
                                                        })}
                                                    >
                                                        <div
                                                            id="rH1"
                                                            style={{
                                                                width: '100%',
                                                                marginBottom: '18px',
                                                                position: 'absolute',
                                                                top: '75%',
                                                                padding: '0px 0px',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {obj?.section_title}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            {modalContent.isOpen && (
                                                <div
                                                    style={{
                                                        position: 'fixed',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        backgroundColor: 'rgba(0,0,0,0.7)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        zIndex: 1000,
                                                        // overflowY: 'auto', 
                                                        // padding: '20px'
                                                    }}
                                                    onClick={() => setModalContent({ ...modalContent, isOpen: false })}
                                                >
                                                    <div className="container55"
                                                        style={{
                                                            backgroundColor: 'white',
                                                            padding: '10px',
                                                            paddingTop: '0px',
                                                            borderRadius: '8px',
                                                            //maxWidth: '500px',
                                                            width: '70%',
                                                            height: '75%',
                                                            maxHeight: '90vh',
                                                            overflow: 'hidden',
                                                            margin: "0 auto",
                                                            textAlign: 'center',
                                                            position: 'relative',
                                                        }}
                                                        onClick={e => e.stopPropagation()}
                                                    >
                                                        <div style={{ display: 'flex', justifyContent: 'end', height: '10%' }}> <span
                                                            className="fc-popCloseTag"
                                                            onClick={() => setModalContent({ ...modalContent, isOpen: false })}
                                                        >
                                                            X
                                                        </span></div>
                                                        <div style={{ height: '80%', overflow: 'auto', }}>
                                                            <h2 style={{
                                                                fontWeight: 'bold',
                                                                marginBottom: '15px',
                                                                color: headerData.primary_color
                                                            }}>{modalContent.title}</h2>
                                                            <div className="modelContainer55" style={{
                                                                height: '55vh',
                                                                width: '50%',
                                                                display: 'flex',           // Add this
                                                                justifyContent: 'center',  // Add this
                                                                alignItems: 'center',      // Add this
                                                                margin: '0 auto'          // Add this
                                                            }}>
                                                                <img
                                                                    src={modalContent.image}
                                                                    id="modelImage"
                                                                    style={{
                                                                        width: "90%",
                                                                        height: '90%',
                                                                        marginBottom: '1px',
                                                                        borderRadius: '4px',
                                                                        textAlign: 'center'
                                                                    }}
                                                                    onClick={() => {
                                                                        setUrlData(modalContent.image);
                                                                        setImpagePreview(true);
                                                                    }}
                                                                    alt={modalContent.title}
                                                                />
                                                            </div>
                                                            <div className="font55"
                                                                style={{
                                                                    fontSize: '14px',
                                                                    lineHeight: '1.6',
                                                                    color: '#333',

                                                                }}
                                                                dangerouslySetInnerHTML={{ __html: modalContent.description }}
                                                            />
                                                        </div>

                                                        <button id="closesec"
                                                            style={{
                                                                backgroundColor: headerData.primary_color,
                                                                color: 'white',
                                                                border: 'none',
                                                                padding: '8px 16px',
                                                                borderRadius: '4px',
                                                                marginTop: '15px',
                                                                cursor: 'pointer',
                                                                height: '5%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                margin: '15px auto 0'
                                                            }}
                                                            onClick={() => setModalContent({ ...modalContent, isOpen: false })}
                                                        >
                                                            Close
                                                        </button>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                }
                            })}

                    </div>
                </div>

            </div >
        </>
    );
};

export default App;
